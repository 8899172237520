<template>
  <!-- 阿拉伯语镜像 -->

  <div id="app" v-if="$store.getters.getStatsigClient && $language" :dir="$store.getters.dir">
    <router-view></router-view>
    <van-icon @click="showLanguageSetting = true" v-if="webConfig.NODE_ENV === 'development'" class="language-setting"
      size="25" color="#fff" name="setting-o" />
    <van-popup v-if="webConfig.NODE_ENV === 'development'" position="bottom" v-model="showLanguageSetting">
      <van-picker show-toolbar title="标题" :columns="languageColumns" @confirm="onConfirm"
        @cancel="showLanguageSetting = false" />
    </van-popup>

  </div>
</template>

<script>
import webConfig from "@/config/web.config/index";
import { initStatsig } from "@/utils/statsig";
import { setmMxpanelUserInfo, setmMxpanelValue } from "@/utils/mixpanel"
// import { StatsigClient } from '@statsig/js-client'
// import { runStatsigSessionReplay } from '@statsig/session-replay';
// import { runStatsigAutoCapture } from '@statsig/web-analytics';
// import { getFirestoreDataByUid } from "@/utils/firebase"
// import { transferSubscription, getSubscriptionList } from "./api/system";
export default {
  name: "App",
  data() {
    return {
      languageColumns: [],
      showLanguageSetting: false,
      webConfig,
      currentLanguage: 'es',
    }
  },
  beforeCreate() {
    //初始化statsig
    initStatsig()
  },
  created() {
    this.currentLanguage = this.$language;
    this.webConfig = webConfig;
    //读取onboarding本地数据
    let onBoardingRecordInfo = window.sessionStorage.getItem('onBoardingRecordInfo');
    if (onBoardingRecordInfo) {
      this.$store.commit('updateOnBoardingRecordInfo', JSON.parse(onBoardingRecordInfo));
    }
    // 生产环境禁用 console.log
    if (webConfig.NODE_ENV === 'production') {
      console.log = () => { };
    }
    //本地环境
    if (webConfig.NODE_ENV === 'development') {
      this.languageColumns = webConfig.languageList;
    }
  },
  mounted() {
    // const officialAccountUid = 'jsLrYH9nTNVB2M2zoGC7lDQotUt1'
    // const sourceUid = 'jsLrYH9nTNVB2M2zoGC7lDQotUt1'
    // getSubscriptionList(sourceUid).then(({ data }) => {


    //   if (data && data.length) {
    //     const curSubscriptionData = data.find(item => item.active == 1);
    //     transferSubscription(officialAccountUid, { sourceUid, subscriptionId: curSubscriptionData.subscriptionId })

    //   }


    // })

    // getFirestoreDataByUid('Khn5PU5n22a915U9wgUILPmBsHG2').then(res => {
    //   window.sessionStorage.setItem('userInfos', JSON.stringify(res))
    //   // console.log(res);
    //   const newUid = 'EVbGlNI8IuYo4khtZuE3edRQHRm1'
    //   res.uid = newUid;
    //   // addFirestoreData(newUid, res)

    // })
    // const mixpanelDeviceId = window.sessionStorage.getItem("mixpanelDeviceId");
    // //录屏
    // const myStatsigClient = new StatsigClient(
    //   webConfig.statsigId,
    //   { userID: mixpanelDeviceId }
    // );
    // console.log(myStatsigClient._user.userID);
    // runStatsigSessionReplay(myStatsigClient);
    // runStatsigAutoCapture(myStatsigClient);
    // await myStatsigClient.initializeAsync();
    const urlParams = this.$utils.getUrlParams(window.location.href);
    window.sessionStorage.setItem('urlQuery', JSON.stringify(urlParams));
    const lpid = urlParams.lpid;//价格a/b测试
    let webLanguage =
      navigator.language ||
      navigator.userLanguage ||
      navigator.browserLanguage ||
      navigator.systemLanguage;

    // 设置mixpanel用户属性
    let userInfo = {
      user_source: "web",
      user_type: "new",
      web_version: webConfig.webVersion,
      price_group: this.$utils.getMixpanelPriceGroup(lpid),
      language_code: webLanguage, //浏览器当前语言
    }


    // 需要检查的参数列表
    const utmParams = ['utm_source', 'utm_medium', 'utm_campaign'];
    // 动态添加存在的 UTM 参数
    utmParams.forEach(param => {
      if (urlParams[param]) {
        userInfo[param] = urlParams[param];
      }
    });
    console.log(userInfo);

    setmMxpanelUserInfo(
      userInfo
    )
    document.body.addEventListener('touchstart', function () { }); //移动端:active伪类无效的解决方法
    // 阻止双击放大页面
    document.addEventListener('dblclick', function (event) {
      event.preventDefault();
    }, { passive: false });
  },
  methods: {
    onConfirm(lang) {
      window.sessionStorage.setItem('currentLanguage', lang);
      window.location.reload();// 重新加载

    },
    onCancel() {

    }
  }

};
</script>
<style lang="scss" scoped>
.language-setting {
  padding: 5px;
  width: 30px;
  height: 30px;
  z-index: 999999;
  line-height: 30px;
  text-align: center;
  position: fixed;
  right: 10px;
  bottom: 100px;
  border-radius: 50%;
  background: var(--mainColor);
  color: #fff;
  box-shadow: 0 0 5px #888;

}
</style>